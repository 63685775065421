import React from "react";
import { graphql } from "gatsby";
import ArticlesComponent from "../components/articles";
import Layout from "../components/layout";

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiArticle(
      filter: { status: { eq: "published" }, category: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          slug
          title
          description
          publishedAt
          percentage
          category {
            name
          }
          authors {  
            name
            picture {
              childImageSharp {
                fixed(width: 30, height: 30, quality: 100) {
                src                
                }
              }
            }
          }
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
    }
  }
`;


// image {
//   childImageSharp {
//     fixed(width: 660) {
//       src
//     }
//   }
// }

const Category = ({ location, data }) => {
  const articles = data.articles.edges;
  const category = data.category.name;


  const numArticles = data.articles.edges.length
  const maxPost = 10
  const calculatedPages = Math.ceil(numArticles / maxPost);

  const canonicalUrl = location.pathname
  var numberPage = canonicalUrl.match(/[0-9]+/g)


  const seo = {
    metaTitle: category,
    metaDescription: `All ${category} news articles, analyzed for media bias with NLP and AI.`,
  };


  const rawDateArray = []
  var sortedDateArray = []
  var testDateArray = []

  {
    articles.map((article, i) => {
      rawDateArray.push(article.node.publishedAt)
      testDateArray.push(article.node.publishedAt)
    })
  }
  sortedDateArray = testDateArray.sort()


  function rearrange(arr, ind) {
    var i, j, buf, temp;

    for (j = 0; j < ind.length; j++) {
      if (ind[j] >= 0) { // Found a cycle to resolve
        i = ind[j];
        buf = arr[j];
        while (i !== j) { // Not yet back at start of cycle
          // Swap buffer with element content
          temp = buf;
          buf = arr[i];
          arr[i] = temp;
          // Invert bits, making it negative, to mark as visited
          ind[i] = -ind[i] - 1;
          // Visit next element in cycle
          i = -ind[i] - 1;
        }
        // dump buffer into final (=first) element of cycle
        arr[j] = buf;
      } else {
        ind[j] = -ind[j] - 1; // restore
      }
    }
  }

  var rightIndexArray = []

  var k = 0
  var p = 0
  while (k < rawDateArray.length) {
    for (p = 0; p < sortedDateArray.length; p++) {
      if (rawDateArray[k] == sortedDateArray[p]) {
        rightIndexArray.push(p)
        break;
      }
    }
    k++;
  }

  rearrange(articles, rightIndexArray);





  const filteredArticles = []
  {
    articles.slice(0).reverse().map((article, i) => {
      if (numberPage == null && i < maxPost) {
        filteredArticles.push(article)
      } else {
        //these are the indexes we need
        const max = numberPage * maxPost
        const min = max - (maxPost)
        if (i >= min && i <= max) {
          filteredArticles.push(article)
        }
      }
    })
  }





  return (
    <Layout seo={seo}>
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <h1 id="articleTitle">{category}</h1>
          <ArticlesComponent articles={filteredArticles} numPage={numberPage} maxPage={calculatedPages} category={category} />
          {/* category={category + "/"} /> */}
        </div>
      </div>
    </Layout>
  );
};

export default Category;
